import React from 'react';

import {
  dateHourFormatter,
  poundFormatter,
  boolFormatter
} from '../../../../utils';
import {
  ISubInventory,
  IInventoryData
} from '../../../../interfaces/IInventoryItem';
import ExpandRight from '../../../../components/ExpandRight';
import SubinventoryModal from '../SubinventoryModal';

const SubinventoryList: React.SFC<{
  productCode: string;
  productDescription: string;
  channel: string;
  subinventories: ISubInventory[];
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
}> = ({
  productCode,
  productDescription,
  channel,
  subinventories,
  inventoryData,
  setInventoryData
}) => (
  <>
    {subinventories.map((subinventory: ISubInventory) => (
      <ExpandRight
        key={subinventory.subinventory}
        title={subinventory.subinventory}
        subtitle={{
          code: 'Total',
          value:
            subinventory.stockOnHand === null ||
            subinventory.stockOnHand === undefined
              ? '-'
              : `${subinventory.stockOnHand} items`
        }}
        subinventoryEntries={[
          {
            code: 'Stock on hand',
            value:
              subinventory.stockOnHand === null ||
              subinventory.stockOnHand === undefined
                ? '-'
                : subinventory.stockOnHand.toString()
          },
          {
            code: 'Reserved',
            value:
              subinventory.reserved === null ||
              subinventory.reserved === undefined
                ? '-'
                : subinventory.reserved.toString()
          },
          {
            code: 'Can sell',
            value: boolFormatter(subinventory.canSell)
          },
          {
            code: 'Transfer price',
            value: poundFormatter(subinventory.transferPrice)
          },
          {
            code: 'Forward order',
            value: boolFormatter(subinventory.forwardOrder)
          },
          {
            code: 'Back order',
            value: boolFormatter(subinventory.backOrder)
          },
          {
            code: 'Release date',
            value: dateHourFormatter(subinventory.releaseDate)
          }
        ]}
      >
        <SubinventoryModal
          productCode={productCode}
          productDescription={productDescription}
          channel={channel}
          subinventory={subinventory}
          inventoryData={inventoryData}
          setInventoryData={setInventoryData}
        />
      </ExpandRight>
    ))}
  </>
);

export default SubinventoryList;
