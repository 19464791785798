import styled from 'styled-components';
import { Accordion } from '@material-ui/core';

export default styled(Accordion)`
  margin: 10px 0 0 0 !important;
  width: 100%;

  .inner-accordion__summary {
    padding: 10px;
    min-height: 0;

    .MuiAccordionSummary-content {
      display: flex;
      flex-direction: column;

      h4 {
        margin: 0;
        font-size: 20px;
      }

      p {
        font-size: 16px;
        color: #666666;
        margin 5px 0 0 0;
      }
    }

    &.Mui-expanded {
      min-height: 0;
    }
  }

  .inner-accordion__details {
    padding: 10px;

    .inner-accordion__details__subtitle {
      margin: 10px 0;
      font-size: 22px;
      color: ${(props) => props.theme.colors.black}
    }
  }

  &.Mui-expanded .inner-accordion__summary {
    background-color: #f2f2f2;
  }
`;
