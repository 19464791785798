import moment from 'moment';

import { IInvoiceAddress } from '../interfaces/IOrder';

export const dateFormatter = (date: string): string =>
  date ? moment(date).format('LL') : '-';

export const hourFormatter = (date: string): string =>
  date ? moment(date).format('LT') : '-';

export const dateHourFormatter = (date: string): string =>
  date ? `${dateFormatter(date)} ${hourFormatter(date)}` : '-';

export const addressFormatter = (address: IInvoiceAddress): string =>
  Object.values(address).filter(Boolean).join(', ');

export const poundFormatter = (value: number): string =>
  value === null || value === undefined ? '-' : `£ ${(value / 100).toFixed(2)}`;

export const boolFormatter = (value: boolean): string => (value ? 'Y' : 'N');
