import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';

export default styled(FormControlLabel)`
  margin-left: -11px;

  .MuiIconButton-label {
    color: ${(props) => props.theme.colors.blue};
  }

  .MuiFormControlLabel-label {
    font-family: Vodafone Lt;
    font-size: 20px;
    color: ${(props) => props.theme.colors.white};
  }
`;
