import styled from 'styled-components';
import { InputLabel } from '@material-ui/core';

export default styled(InputLabel)`
  && {
    color: ${(props) => props.theme.colors.white};
    font-family: Vodafone Lt;
    font-size: 16px;
    display: flex;
    flex-direction: column;
  }

  &.label--w-20 {
    width: 20%;
  }

  &.label--w-60 {
    width: 60%;
  }

  .MuiIconButton-label {
    color: ${(props) => props.theme.colors.blue};
  }

  .MuiInputBase-root {
    border-radius: 0;
    margin-top: 4px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }
`;
