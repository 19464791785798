import React from 'react';

import { dateFormatter } from '../../../../utils';
import CustomAccordion from '../../../../components/CustomAccordion';
import DeliveryGroupList from '../DeliveryGroupList';
import OrderSummary from '../OrderSummary';
import { IOrder, IValidationError } from '../../../../interfaces/IOrder';
import Error from '../../../../components/Error';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';

const Order: React.SFC<{ order: IOrder }> = ({ order }) => (
  <CustomAccordion
    key={order.orderId}
    status={order.status}
    primaryTitle={order.orderId}
    secondaryTitle={dateFormatter(order.dateTimeRaised)}
    summaryEntries={[
      {
        code: 'Origin',
        value: order.origin.toString()
      },
      {
        code: 'Parent order',
        value: order.parentOrderId ? order.parentOrderId : '-'
      },
      {
        code: 'Customer',
        value: order.customerEmail
      }
    ]}
  >
    {order.status === 'REJECTED' ? (
      order.validationErrors.map((err: IValidationError) => (
        <Error
          key={err.errorCode}
          title={err.errorCode}
          description={err.errorMessage}
        >
          {err.deliveryGroupNumber && (
            <GenericEntry
              code='Delivery group'
              value={`No. ${err.deliveryGroupNumber}`}
            />
          )}
          {err.lineItemId && err.productCode && (
            <GenericEntry
              code='Line item'
              value={`No. ${err.lineItemId} - ${err.productCode}`}
            />
          )}
        </Error>
      ))
    ) : (
      <>
        <OrderSummary order={order} />
        <DeliveryGroupList deliveryGroups={order.deliveryGroups} />
      </>
    )}
  </CustomAccordion>
);

export default Order;
