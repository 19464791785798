import React from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import StyledLayout from './styles';
import cavendishTheme from './assets/theme';
import history from './services/history';
import Header from './components/Header';
import Footer from './components/Footer';
import OrderManagement from './pages/OrderManagement';
import InventoryManagement from './pages/InventoryManagement';
import Toast from './components/Toast';

const App: React.SFC = () => (
  <ThemeProvider theme={cavendishTheme}>
    <Toast />
    <StyledLayout>
      <Router history={history}>
        <Header />
        <div className='page-content'>
          <Route exact path='/' />
          <Route exact path='/order-management' component={OrderManagement} />
          <Route
            exact
            path='/inventory-management'
            component={InventoryManagement}
          />
          <Route path='/help' />
          <Redirect to='/' />
        </div>
      </Router>
      <Footer />
    </StyledLayout>
  </ThemeProvider>
);

export default App;
