import React from 'react';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';

import ExpandIcon from '../../assets/icons/expand.svg';
import Status from '../Status';
import StyledAccordion from './styles';
import cavendishTheme from '../../assets/theme';
import GenericEntry from '../GenericSection/components/GenericEntry';
import { IGenericEntry } from '../../interfaces/IGenericSection';

interface ICustomAccordionProps {
  primaryTitle: string;
  secondaryTitle: string;
  status: string;
  summaryEntries: IGenericEntry[];
}

const CustomAccordion: React.SFC<ICustomAccordionProps> = ({
  primaryTitle,
  secondaryTitle,
  status,
  summaryEntries,
  children
}) => {
  const color: string = ['active', 'accepted'].includes(
    status.toLocaleLowerCase()
  )
    ? cavendishTheme.colors.blue
    : cavendishTheme.colors.purple;

  return (
    <StyledAccordion color={color}>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt='Expand' />}
        className='custom-accordion__summary accordion__summary--outlined'
      >
        <Status color={color} />
        <div className='row'>
          <div className='custom-accordion__summary__title column'>
            <p>{secondaryTitle}</p>
            <h2>{primaryTitle}</h2>
            <GenericEntry code='Status' value={status} />
          </div>
          <div className='column'>
            {summaryEntries.map((entry: IGenericEntry) => (
              <GenericEntry
                key={entry.code}
                code={entry.code}
                value={entry.value}
              />
            ))}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className='custom-accordion__details accordion__details--display-column'>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default CustomAccordion;
