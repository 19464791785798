import React, { useState, SyntheticEvent } from 'react';
import {
  DialogTitle,
  DialogContent,
  Container,
  IconButton
} from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';

import CustomButton from '../../../../components/CustomButton';
import cavendishTheme from '../../../../assets/theme';
import EditIcon from '../../../../assets/icons/edit.svg';
import CloseIcon from '../../../../assets/icons/close.svg';
import StyledDialog from './styles';
import ControlledInput from '../../../../components/ControlledInput';
import ControlledCheckbox from '../../../../components/ControlledCheckbox';
import environment from '../../../../environment';
import LoadingScreen from '../../../../components/LoadigScreen';
import {
  IInventoryChannel,
  IInventoryItem,
  ISubInventory,
  IInventoryData
} from '../../../../interfaces/IInventoryItem';

interface ISubInventoryProps {
  productCode: string;
  productDescription: string;
  channel: string;
  subinventory: ISubInventory;
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
}

const SubinventoryModal: React.FC<ISubInventoryProps> = ({
  productCode,
  productDescription,
  channel,
  subinventory,
  inventoryData,
  setInventoryData
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stockOnHand, setStockOnHand] = useState(subinventory.stockOnHand);
  const [canSell, setCanSell] = useState(subinventory.canSell);
  const [forwardOrder, setForwardOrder] = useState(subinventory.forwardOrder);
  const [backOrder, setBackOrder] = useState(subinventory.backOrder);
  const [releaseDate, setReleaseDate] = useState(subinventory.releaseDate);

  const handleSuccess = () => {
    setOpen(false);
    setLoading(false);
    setInventoryData({
      ...inventoryData,
      items: inventoryData.items.map((item: IInventoryItem) =>
        item.productCode === productCode
          ? {
              ...item,
              channels: item.channels.map((ch: IInventoryChannel) =>
                ch.channelName === channel
                  ? {
                      ...ch,
                      subinventories: ch.subinventories.map(
                        (sb: ISubInventory) =>
                          sb.subinventory === subinventory.subinventory
                            ? {
                                ...sb,
                                stockOnHand,
                                canSell,
                                forwardOrder,
                                backOrder,
                                releaseDate
                              }
                            : sb
                      )
                    }
                  : ch
              )
            }
          : item
      )
    });
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);

    axios
      .put(
        `${environment.apiPath}product/${productCode}/${subinventory.subinventory}`,
        {
          stockOnHand,
          canSell,
          forwardOrder,
          backOrder,
          releaseDate
        },
        { ...environment.params }
      )
      .then(
        () => handleSuccess(),
        (err) => {
          toast.error(err.message);
          setLoading(false);
          setOpen(false);
        }
      );
  };

  return (
    <>
      <CustomButton
        title='Update subinventory'
        startIcon={<img src={EditIcon} alt='Edit' />}
        classes='btn--small btn--grey'
        handleClick={() => setOpen(true)}
      />

      <StyledDialog
        fullScreen
        open={open}
        aria-labelledby='dialog-title'
        PaperProps={{
          style: {
            backgroundColor: cavendishTheme.colors.black
          }
        }}
      >
        <Container fixed className='close-btn'>
          <IconButton
            tabIndex={0}
            aria-label='Close'
            onClick={() => setOpen(false)}
            className='close'
          >
            <img src={CloseIcon} alt='Close' />
          </IconButton>
        </Container>

        <DialogTitle id='dialog-title'>{productCode}</DialogTitle>
        <h3>{productDescription}</h3>
        <div className='dialog-subtitle'>
          <p>
            {channel} - {subinventory.subinventory}
          </p>
        </div>

        <DialogContent className='dialog-content'>
          <form
            noValidate
            autoComplete='off'
            onSubmit={handleSubmit}
            className='dialog-content__form'
          >
            <ControlledInput
              id='soh'
              label='Stock on hand'
              type='number'
              value={stockOnHand}
              placeholder='Type stock on hand here'
              handleChange={(event: any) => setStockOnHand(event.target.value)}
            />
            <ControlledCheckbox
              checked={canSell}
              handleChange={(event: any) => setCanSell(event.target.checked)}
              label='Can sell'
            />
            <ControlledCheckbox
              checked={forwardOrder}
              handleChange={(event: any) =>
                setForwardOrder(event.target.checked)
              }
              label='Forward order'
            />
            <ControlledCheckbox
              checked={backOrder}
              handleChange={(event: any) => setBackOrder(event.target.checked)}
              label='Backorder'
            />
            <ControlledInput
              id='release-date'
              label='Release date'
              type='datetime-local'
              value={releaseDate}
              handleChange={(event: any) => setReleaseDate(event.target.value)}
            />
            <CustomButton title='Update' type='submit' classes='btn--w-100' />
          </form>
        </DialogContent>
        {loading && <LoadingScreen />}
      </StyledDialog>
    </>
  );
};

export default SubinventoryModal;
