import React from 'react';

import StyledNoResultsScreen from './styles';
import VodafoneOutline from '../../assets/icons/vodafone-outline.png';

const NoResultsScreen: React.SFC<{ message: string }> = ({ message }) => (
  <StyledNoResultsScreen>
    <div className='no-results-title'>
      <img src={VodafoneOutline} alt='Vodafone logo outlined' />
      <h2>No results</h2>
    </div>
    <p>{message}</p>
  </StyledNoResultsScreen>
);

export default NoResultsScreen;
