import styled from 'styled-components';
import { FormControl } from '@material-ui/core';

export default styled(FormControl)`
  .MuiInputBase-root {
    background-color: ${(props) => props.theme.colors.lightGrey};

    .MuiSelect-root {
      color: ${(props) => props.theme.colors.darkGrey};
      font-size: 20px;
      font-family: Vodafone Rg;
      line-height: 26px;
      padding: 12px;
    }
  }
`;
