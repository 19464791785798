import React from 'react';

import StyledGenericEntry from './styles';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';

const GenericEntry: React.SFC<IGenericEntry> = ({ code, value }) => (
  <StyledGenericEntry>
    {code}: <span>{value}</span>
  </StyledGenericEntry>
);

export default GenericEntry;
