import React from 'react';

import StyledHeroBanner from './styles';

const HeroBanner: React.SFC<{ title: string; background: string }> = ({
  title,
  background
}) => (
  <StyledHeroBanner background={background} data-testid='hero-testid'>
    <h1>{title}</h1>
  </StyledHeroBanner>
);

export default HeroBanner;
