import React from 'react';
import { CircularProgress } from '@material-ui/core';

import StyledLoadingScreen from './styles';

const LoadingScreen: React.SFC = () => (
  <StyledLoadingScreen>
    <CircularProgress className='circular-progress--color-red' />
    <p>Loading...</p>
  </StyledLoadingScreen>
);

export default LoadingScreen;
