import React from 'react';

import OrganizationIcon from '../../../../assets/icons/organization.svg';
import {
  ISubInventory,
  IInventoryChannel,
  IInventoryData
} from '../../../../interfaces/IInventoryItem';
import GenericTitle from '../../../../components/GenericSection/components/GenericTitle';
import InnerAccordion from '../../../../components/InnerAccordion';
import SubinventoryList from '../SubinventoryList';

const ChannelList: React.SFC<{
  productCode: string;
  productDescription: string;
  channels: IInventoryChannel[];
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
}> = ({
  productCode,
  productDescription,
  channels,
  inventoryData,
  setInventoryData
}) => {
  const getTotalItems = (ch: IInventoryChannel) =>
    ch.subinventories.reduce(
      (total: number, sb: ISubInventory) => total + Number(sb.stockOnHand),
      0
    );

  return (
    <>
      <GenericTitle
        img={OrganizationIcon}
        altText='Organization'
        title='Organization channels'
      />

      {channels.map((channel: IInventoryChannel) => (
        <InnerAccordion
          key={channel.channelId}
          title={`${channel.channelId} - ${channel.channelName}`}
          description={{
            code: 'Total',
            value: `${getTotalItems(channel)} items`
          }}
        >
          <h5 className='inner-accordion__details__subtitle '>
            Subinventories
          </h5>
          <SubinventoryList
            productCode={productCode}
            productDescription={productDescription}
            channel={channel.channelName}
            subinventories={channel.subinventories}
            inventoryData={inventoryData}
            setInventoryData={setInventoryData}
          />
        </InnerAccordion>
      ))}
    </>
  );
};

export default ChannelList;
