import React, { useState } from 'react';

import DeliveryIcon from '../../../../assets/icons/stock.svg';
import ArrowRight from '../../../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../../../assets/icons/arrow-left.svg';
import GenericTitle from '../../../../components/GenericSection/components/GenericTitle';
import InnerAccordion from '../../../../components/InnerAccordion';
import DeliveryDetails from '../DeliveryDetails';
import OrderItemList from '../OrderItemList';
import { IDeliveryGroup } from '../../../../interfaces/IOrder';
import DeliveryPipeline from '../DeliveryPipeline';
import CustomButton from '../../../../components/CustomButton';

const DeliveryGroupList: React.SFC<{ deliveryGroups: IDeliveryGroup[] }> = ({
  deliveryGroups
}) => {
  const [displayItems, setDisplayItems] = useState(true);

  return (
    <>
      <GenericTitle
        img={DeliveryIcon}
        altText='Delivery'
        title='Delivery groups'
      />

      {deliveryGroups.map((deliveryGroup: IDeliveryGroup) => (
        <InnerAccordion
          key={deliveryGroup.number}
          title={`${deliveryGroup.number} - ${
            deliveryGroup.details.dispatchMethod
              ? deliveryGroup.details.dispatchMethod
              : 'REVERSE'
          }`}
          description={{
            code: 'Status',
            value: deliveryGroup.status
          }}
        >
          {displayItems ? (
            <>
              <DeliveryDetails
                details={deliveryGroup.details}
                consignmentNumber={deliveryGroup.consignmentNumber}
                expectedDeliveryDate={deliveryGroup.expectedDeliveryDate}
              />
              <OrderItemList items={deliveryGroup.items} />
              <div className='row row--justify-end'>
                <CustomButton
                  title='Delivery pipeline'
                  endIcon={<img src={ArrowRight} alt='Arrow right' />}
                  handleClick={() => setDisplayItems(false)}
                />
              </div>
            </>
          ) : (
            <>
              <DeliveryPipeline history={deliveryGroup.history || []} />
              <div className='row'>
                <CustomButton
                  title='Delivery details'
                  classes='btn--grey'
                  startIcon={<img src={ArrowLeft} alt='Arrow left' />}
                  handleClick={() => setDisplayItems(true)}
                />
              </div>
            </>
          )}
        </InnerAccordion>
      ))}
    </>
  );
};

export default DeliveryGroupList;
