import React from 'react';

import ErrorIcon from '../../assets/icons/error.svg';
import StyledError from './styles';

const Error: React.SFC<{ title: string; description: string }> = ({
  title,
  description,
  children
}) => (
  <StyledError>
    <div className='error__icon'>
      <img src={ErrorIcon} alt='Error' />
    </div>
    <div className='error__details'>
      <div className='error__details__description'>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div>{children}</div>
    </div>
  </StyledError>
);

export default Error;
