import styled from 'styled-components';

export default styled.div`
  display: flex;

  &:nth-child(even) {
    .expand-right__button {
      border-left: 2px solid ${(props) => props.theme.colors.darkGrey};
    }
  }

  &:nth-child(odd) {
    .expand-right__button {
      border-left: 2px solid ${(props) => props.theme.colors.vodafoneRed};
    }
  }

  .expand-right__button {
    width: calc(70% - 10px);
    height: fit-content;
    margin: 5px 5px 5px 0;
    border: 1px solid ${(props) => props.theme.colors.grey};
    border-radius: 0;
    background-color: ${(props: { theme: any; expanded: boolean }) =>
      props.expanded ? props.theme.colors.lightGrey : props.theme.colors.white};
    box-shadow: ${(props: { expanded: boolean }) =>
      props.expanded
        ? '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        : 'none'};
    text-transform: none;
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.black};

    .MuiButton-label {
      display: flex;
      justify-content: space-between;

      .expand-right__button__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h5 {
          font-size: 18px;
          line-height: 24px;
          margin: 0;

          span {
            color: ${(props) => props.theme.colors.vodafoneRed};
          }
        }

        p {
          font-size: 14px;
          line-height: 16px;
          color: ${(props) => props.theme.colors.shadowGrey};
          margin: 0;
        }

        .expand-right__button__title__description {
          margin-bottom: 10px;

          & > * {
            margin-right: 15px;
            color: ${(props) => props.theme.colors.black};
          }
        }
      }

      img {
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        -ms-transition: -ms-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transform: rotate(
          ${(props: { expanded: boolean }) => (props.expanded ? 180 : 0)}deg
        );
      }
    }
  }

  .expand-right__content {
    width: calc(30% - 10px);
    margin: 5px 0 5px 5px;
    padding: 5px;
    background-color: ${(props) => props.theme.colors.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.grey};
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .expand-right__content__details {
      // column-count: 2;

      p {
        font-size: 16px;
        margin: 0;
        padding: 5px;
      }
    }
  }
`;
