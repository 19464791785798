import React from 'react';
import { Container } from '@material-ui/core';

import StyledFooter from './styles';

const Footer: React.SFC = () => (
  <StyledFooter>
    <Container fixed>
      <p>&#169; Vodafone 2020</p>
    </Container>
  </StyledFooter>
);

export default Footer;
