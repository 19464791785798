import React, { useState } from 'react';
import { Button, Divider } from '@material-ui/core';

import { boolFormatter } from '../../../../utils';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import Order from '../Order';
import ExpandIcon from '../../../../assets/icons/expand.svg';
import StyledParentOrder from './styles';
import { IOrder } from '../../../../interfaces/IOrder';

const ParentOrder: React.FC<{ order: IOrder }> = ({ order }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledParentOrder expanded={expanded}>
      <div className='parent-order__title'>
        <h2>{order.orderId}</h2>
        <GenericEntry
          code='Service orders'
          value={order.childrenCount.toString()}
        />
        <GenericEntry
          code='Part shipment'
          value={boolFormatter(order.acceptPartShipmentFlag)}
        />
      </div>

      {expanded && (
        <>
          <Divider />
          <div className='parent-order__content'>
            <div className='parent-order__content__details'>
              {order.children &&
                order.children.map((childOrder: IOrder) => (
                  <Order key={childOrder.orderId} order={childOrder} />
                ))}
            </div>
          </div>
        </>
      )}

      <Divider />

      <Button onClick={toggleExpand} className='parent-order__button'>
        <span>{expanded ? 'Show less' : 'Show more'}</span>
        <img src={ExpandIcon} alt='Expand' />
      </Button>
    </StyledParentOrder>
  );
};

export default ParentOrder;
