import styled from 'styled-components';
import { Accordion } from '@material-ui/core';

export default styled(Accordion)`
  margin: 20px 0 !important;
  margin: 0;
  border-radius: 0 !important;

  .accordion--angular {
    margin: 0;
    border-radius: 0 !important;
  }

  p {
    font-size: 18px;
  }

  .row {
    display: flex;
    width: 100%;

    .column {
      width: 50%;
    }
  }

  .row--justify-end {
    justify-content: flex-end;
  }

  .row--justify-space-between {
    justify-content: space-between;
  }

  .custom-accordion__summary {
    padding: 0 20px 0 0;

    .custom-accordion__summary__title {
      color: ${(props) => props.theme.colors.black};

      h2 {
        font-size: 42px;
        margin: 0;
      }

      p {
        font-size: 22px;
        margin: 10px 0;

        span {
          color: ${(props: { color: string }) => props.color};
        }
      }
    }
  }

  .custom-accordion__details {
    padding: 0 20px 20px 27px;
  }

  .accordion__summary--outlined {
    border: 1px solid ${(props) => props.theme.colors.grey};

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .accordion__details--display-column {
    display: flex;
    flex-direction: column;
  }
`;
