import React from 'react';

import { dateFormatter, addressFormatter } from '../../../../utils';
import GenericEntry from '../../../../components/GenericSection/components/GenericEntry';
import { IDeliveryGroupDetails } from '../../../../interfaces/IOrder';

const DeliveryDetails: React.SFC<{
  details: IDeliveryGroupDetails;
  consignmentNumber?: string;
  expectedDeliveryDate?: string;
}> = ({ details, consignmentNumber, expectedDeliveryDate }) => (
  <>
    <h5 className='inner-accordion__details__subtitle '>Delivery details</h5>
    <div className='row'>
      <div className='column'>
        <GenericEntry code='Contact' value={details.contact} />
        <GenericEntry
          code='Address'
          value={addressFormatter(details.address)}
        />
        <GenericEntry
          code='Requested shipment date'
          value={dateFormatter(details.scheduledDeliveryDate)}
        />
      </div>

      <div className='column'>
        <GenericEntry
          code='Consignment number'
          value={consignmentNumber || '-'}
        />
        <GenericEntry
          code='Expected delivery date'
          value={dateFormatter(expectedDeliveryDate || '')}
        />
        <GenericEntry code='Service' value={details.service} />
      </div>
    </div>
  </>
);

export default DeliveryDetails;
