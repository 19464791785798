import React, { useState, ChangeEvent } from 'react';
import { Container } from '@material-ui/core';

import HeroBalloons from '../../assets/heros/hero-balloons.png';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import HeroBanner from '../../components/HeroBanner';
import SearchBar from '../../components/SearchBar';
import CustomAccordion from '../../components/CustomAccordion';
import CustomButton from '../../components/CustomButton';
import Error from '../../components/Error';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import ItemSummary from './components/ItemSummary';
import ChannelList from './components/ChannelList';
import {
  IInventoryData,
  IInventoryItem
} from '../../interfaces/IInventoryItem';
import NoResultsScreen from '../../components/NoResultsScreen';
import CustomPagination from '../../components/CustomPagination';
import AuditTrail from './components/AuditTrail';

const searchOptions: IGenericEntry[] = [
  {
    code: 'productCode',
    value: 'Product code'
  },
  {
    code: 'description',
    value: 'Product description'
  }
];

const initialInventory: IInventoryData = {
  count: -1,
  allItems: true,
  items: []
};

const InventoryManagement: React.FC = () => {
  const [contentIdx, setContentIdx] = useState(1);
  const [inventoryData, setInventoryData] = useState<IInventoryData>(
    initialInventory
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const pageSize = 5;

  const handleSuccess = (data: IInventoryData) => {
    setPageNumber(1);
    setInventoryData(data);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const handleContentChange = (idx: number) => {
    setContentIdx(idx);
  };

  const clearInventory = () => {
    setInventoryData(initialInventory);
    setContentIdx(1);
  };

  const renderDetails = (item: IInventoryItem) => {
    switch (contentIdx) {
      case 1:
        return (
          <>
            <ItemSummary item={item} />
            <ChannelList
              productCode={item.productCode}
              productDescription={item.description}
              channels={item.channels}
              inventoryData={inventoryData}
              setInventoryData={setInventoryData}
            />
            <div className='row row--justify-space-between'>
              <CustomButton
                title='Sales dashboard'
                classes='btn--grey'
                startIcon={<img src={ArrowLeft} alt='Arrow left' />}
                handleClick={() => handleContentChange(0)}
              />
              <CustomButton
                title='Audit trail'
                endIcon={<img src={ArrowRight} alt='Arrow right' />}
                handleClick={() => handleContentChange(2)}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <AuditTrail productCode={item.productCode} />
            <div className='row'>
              <CustomButton
                title='Product details'
                classes='btn--grey'
                startIcon={<img src={ArrowLeft} alt='Arrow left' />}
                handleClick={() => handleContentChange(1)}
              />
            </div>
          </>
        );
      default:
        return (
          <div className='row row--justify-end'>
            <CustomButton
              title='Product details'
              endIcon={<img src={ArrowRight} alt='Arrow right' />}
              handleClick={() => handleContentChange(1)}
            />
          </div>
        );
    }
  };

  return (
    <>
      <HeroBanner title='Inventory Management' background={HeroBalloons} />
      <SearchBar
        searchOptions={searchOptions}
        buttonTitle='Search for product'
        path='product'
        clearData={clearInventory}
        handleSuccess={(data: IInventoryData) => handleSuccess(data)}
      />

      <Container fixed>
        {!inventoryData.allItems && (
          <Error
            title='Too many items were found for this search!'
            description='If you want to see more results, try your search again with more specific keywords.'
          />
        )}
        {inventoryData.count === 0 && (
          <NoResultsScreen message='Search by product code or product description' />
        )}

        {inventoryData.items
          .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
          .map((item: IInventoryItem) => (
            <CustomAccordion
              key={item.productCode}
              status={item.status}
              primaryTitle={item.productCode}
              secondaryTitle={item.description}
              summaryEntries={[
                {
                  code: 'Product category',
                  value: item.category
                },
                {
                  code: 'Product type',
                  value: item.productType
                },
                {
                  code: 'Last updated',
                  value: '20 August 2020'
                }
              ]}
            >
              {renderDetails(item)}
            </CustomAccordion>
          ))}

        {inventoryData.count > pageSize && (
          <CustomPagination
            itemsCount={inventoryData.count}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
          />
        )}
      </Container>
    </>
  );
};

export default InventoryManagement;
