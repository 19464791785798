import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import StyledExpandRight from './styles';
import ExpandRightIcon from '../../assets/icons/expand-right.svg';
import GenericEntry from '../GenericSection/components/GenericEntry';
import { IGenericEntry } from '../../interfaces/IGenericSection';

interface IExpandRightProps {
  title: string;
  subtitle?: IGenericEntry;
  subinventoryEntries: IGenericEntry[];
  additionalTitleDetails?: string;
  description?: IGenericEntry[];
}

const ExpandRight: React.FC<IExpandRightProps> = ({
  title,
  subtitle,
  subinventoryEntries,
  additionalTitleDetails,
  description,
  children
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledExpandRight expanded={expanded}>
      <Button onClick={toggleExpand} className='expand-right__button'>
        <div className='expand-right__button__title'>
          <h5>
            {additionalTitleDetails && <span>{additionalTitleDetails}</span>}
            {title}
          </h5>
          {description && description.length > 0 && (
            <div className='row expand-right__button__title__description'>
              {description.map(
                (entry: IGenericEntry) =>
                  entry.value && (
                    <GenericEntry
                      key={entry.code}
                      code={entry.code}
                      value={entry.value}
                    />
                  )
              )}
            </div>
          )}
          {subtitle && (
            <GenericEntry code={subtitle.code} value={subtitle.value} />
          )}
        </div>
        <img src={ExpandRightIcon} alt='Expand right' />
      </Button>

      {expanded && (
        <div className='expand-right__content'>
          <div className='expand-right__content__details'>
            {subinventoryEntries.map(
              (entry: IGenericEntry) =>
                entry.value && (
                  <GenericEntry
                    key={entry.code}
                    code={entry.code}
                    value={entry.value}
                  />
                )
            )}
          </div>
          {children}
        </div>
      )}
    </StyledExpandRight>
  );
};

export default ExpandRight;
