import React from 'react';

import StyledPagination from './styles';

interface IPaginationProps {
  itemsCount: number;
  pageSize: number;
  handlePageChange: any;
}

const CustomPagination: React.SFC<IPaginationProps> = ({
  itemsCount,
  pageSize,
  handlePageChange
}) => (
  <StyledPagination
    showFirstButton
    showLastButton
    count={Math.ceil(itemsCount / pageSize)}
    onChange={handlePageChange}
  />
);

export default CustomPagination;
