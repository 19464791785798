import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;

  .delivery-pipeline {
    display: flex;
    flex-direction: column;
    align-items: center;

    .delivery-pipeline__status {
      display: flex;
      align-items: center;
      width: 608px;

      .delivery-pipeline__status__circle {
        padding: 10px;
        width: 120px;
        height: 120px;
        border: 4px solid ${(props) => props.theme.colors.blue};
        border-radius: 50%;
        font-size: 18px;
        color: ${(props) => props.theme.colors.blue};
        text-align: center;
        background: ${(props) => props.theme.colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Vodafone Rg;
        text-transform: capitalize;
        margin: 0 10px;
      }

      .delivery-pipeline__status__circle--sm {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid ${(props) => props.theme.colors.blue};
      }

      hr {
        width: 100px;
        border: 0;
        background-color: ${(props) => props.theme.colors.blue};
        height: 2px;
        margin: 0;
      }

      .delivery-pipeline__status__circle--md {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 5px solid ${(props) => props.theme.colors.blue};
      }

      .delivery-pipeline__status__date {
        margin: 0 10px;

        p {
          margin: 0;
          font-size: 16px;
          padding: 5px;
          font-family: Vodafone Rg;
          color: ${(props) => props.theme.colors.darkGrey};
        }
      }
    }

    .delivery-pipeline__vl {
      height: 40px;
      width: 3px;
      background-color: ${(props) => props.theme.colors.grey};
    }

    &:nth-child(odd) {
      .delivery-pipeline__status {
        flex-direction: row-reverse;
      }
    }

    &:last-child {
      .delivery-pipeline__vl {
        display: none;
      }
    }
  }
`;
