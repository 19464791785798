const cavendishTheme = {
  colors: {
    white: '#FFFFFF',
    lightGrey: '#F2F2F2',
    silverGrey: '#C4C4C4',
    grey: '#D6D6D6',
    shadowGrey: '#666666',
    darkGrey: '#4A4D4E',
    black: '#333333',
    lightRed: '#FF7070',
    vodafoneRed: '#E60000',
    darkRed: '#900',
    lightBlue: '#00B0CA',
    blue: '#007C92',
    purple: '#9C2AA0',
    yellow: '#F9CA00',
    green: '#A8B400'
  }
};

export default cavendishTheme;
