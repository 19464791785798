import React, { useState, SyntheticEvent } from 'react';
import { Container } from '@material-ui/core';
import { toast } from 'react-toastify';
import axios from 'axios';

import StyledSearchBar from './styles';
import CustomButton from '../CustomButton';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import environment from '../../environment';
import LoadingScreen from '../LoadigScreen';
import ControlledInput from '../ControlledInput';
import ControlledSelect from '../ControlledSelect';

export interface ISearchBarProps {
  searchOptions: IGenericEntry[];
  buttonTitle: string;
  path: string;
  handleSuccess: CallableFunction;
  clearData: CallableFunction;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  searchOptions,
  buttonTitle,
  path,
  handleSuccess,
  clearData
}) => {
  const [searchOption, setSearchOption] = useState<IGenericEntry>(
    searchOptions[0]
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearchOptionChange = (event: any) => {
    setSearchOption(event.target.value);
  };

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    clearData();
    setLoading(true);

    axios
      .get(
        `${environment.apiPath}${path}?${searchOption.code}=${searchValue}`,
        { ...environment.params }
      )
      .then(
        (res) => {
          handleSuccess(res.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err.message);
          setLoading(false);
        }
      );
  };

  return (
    <>
      <StyledSearchBar>
        <Container fixed>
          <form noValidate autoComplete='off' onSubmit={handleSubmit}>
            <ControlledSelect
              id='search-option'
              value={searchOption}
              handleChange={handleSearchOptionChange}
              label='Search option'
              options={searchOptions}
            />
            <ControlledInput
              id='search-value'
              placeholder={`Type ${searchOption.value.toLowerCase()} here`}
              value={searchValue}
              handleChange={handleSearchValueChange}
              label={searchOption.value}
              type='text'
              classes='label--w-60'
            />
            <CustomButton
              type='submit'
              disabled={!searchValue}
              classes='btn--w-20'
              title={buttonTitle}
            />
          </form>
        </Container>
      </StyledSearchBar>
      {loading && <LoadingScreen />}
    </>
  );
};

export default SearchBar;
