import React, { useEffect, useCallback, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Tabs, Tab, Container, IconButton } from '@material-ui/core';

import VodafoneLogo from '../../assets/icons/vodafone-logo.svg';
import LoginIcon from '../../assets/icons/user.svg';
import StyledHeader from './styles';

interface IHeaderTab {
  label: string;
  route: string;
}

const headerTabs: IHeaderTab[] = [
  {
    label: 'Home',
    route: '/'
  },
  {
    label: 'Order Management',
    route: '/order-management'
  },
  {
    label: 'Inventory Management',
    route: '/inventory-management'
  },
  {
    label: 'Help',
    route: '/help'
  }
];

const Header: React.FC<RouteComponentProps> = ({ history }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const currentTabIndex: number = headerTabs.findIndex(
      (tab: IHeaderTab) => tab.route === history.location.pathname
    );
    setTabIndex(currentTabIndex);
  }, [history.location]);

  const handleTabChange = useCallback(
    (newTabIndex: number, route: string) => () => {
      setTabIndex(newTabIndex);
      history.push(route);
    },
    [history]
  );

  return (
    <StyledHeader>
      <div className='header__primary-menu' />
      <Container fixed className='header__secondary-menu'>
        <div className='header__secondary-menu__navigation'>
          <img src={VodafoneLogo} alt='Vodafone Logo' />
          <Tabs value={tabIndex}>
            {headerTabs.map((tab, index) => (
              <Tab
                className='header__secondary-menu__navigation__tab'
                tabIndex={0}
                key={tab.label}
                label={tab.label}
                onClick={handleTabChange(index, tab.route)}
              />
            ))}
          </Tabs>
        </div>
        <IconButton tabIndex={0} aria-label='Login'>
          <img src={LoginIcon} alt='Login' />
        </IconButton>
      </Container>
    </StyledHeader>
  );
};

export default withRouter(Header);
