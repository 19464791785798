import React from 'react';

import { poundFormatter, boolFormatter } from '../../../../utils';
import ExpandRight from '../../../../components/ExpandRight';
import { IOrderItem } from '../../../../interfaces/IOrder';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';

const OrderItemList: React.SFC<{ items: IOrderItem[] }> = ({ items }) => (
  <>
    {items.map((item: IOrderItem) => (
      <ExpandRight
        key={item.id}
        title={`${item.productCode} - ${item.description} (${item.productType})`}
        subtitle={{
          code: 'Status',
          value: item.status
        }}
        additionalTitleDetails={item.isAddOn ? '(Add-on)' : ''}
        description={[
          {
            code: 'Serial IMEI',
            value: item.serialIMEI || ''
          },
          {
            code: 'Serial MAC',
            value: item.serialMAC || ''
          },
          {
            code: 'Serial EID',
            value: item.serialEID || ''
          }
        ].filter((entry: IGenericEntry) => entry.value !== '')}
        subinventoryEntries={[
          {
            code: 'Unit selling price',
            value: poundFormatter(item.unitSellingPrice)
          },
          {
            code: 'Line net selling price',
            value: poundFormatter(item.netSellingPrice)
          },
          {
            code: 'Line gross selling price',
            value: poundFormatter(item.grossSellingPrice)
          },
          {
            code: 'VAT amount',
            value: poundFormatter(item.vatAmount)
          },
          {
            code: 'VAT code',
            value: item.vatCode
          },
          {
            code: 'Priority code',
            value: item.shippingDependency
          },
          {
            code: 'Upgrade indicator',
            value: boolFormatter(item.upgradeIndicator)
          }
        ]}
      />
    ))}
  </>
);

export default OrderItemList;
