import React from 'react';

import StyledTextField from './styles';
import Label from '../Label';

interface IControlledInputProps {
  id: string;
  type: string;
  label: string;
  value: any;
  handleChange: any;
  placeholder?: string;
  classes?: string;
}

const ControlledInput: React.SFC<IControlledInputProps> = ({
  id,
  type,
  label,
  value,
  placeholder,
  handleChange,
  classes
}) => (
  <Label
    id={id}
    control={
      <StyledTextField
        data-testid='textfield-testid'
        variant={'outlined' as any}
        type={type}
        placeholder={placeholder}
        inputProps={{
          id,
          shrink: 'true',
          'data-testid': 'textfield-input-testid'
        }}
        value={value}
        onChange={handleChange}
      />
    }
    label={label}
    classes={classes}
  />
);

export default ControlledInput;
