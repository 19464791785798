import React, { useEffect, useState } from 'react';

import {
  hourFormatter,
  dateHourFormatter,
  poundFormatter,
  boolFormatter
} from '../../../../utils';
import StyledAudit from './styles';
import InnerAccordion from '../../../../components/InnerAccordion';
import ExpandRight from '../../../../components/ExpandRight';
import cavendishTheme from '../../../../assets/theme/index';

const AuditStep: React.FC<{ audit: any }> = ({ audit }) => {
  const [color, setColor] = useState(cavendishTheme.colors.darkGrey);
  const [height, setHeight] = useState(0);

  const getEntries = (sb: any) => {
    const subInventoryEntries = Object.keys(sb).filter(
      (key: string) => key !== 'subinventory'
    );

    return subInventoryEntries.map((key: string) => {
      switch (key) {
        case 'stockOnHand':
          return {
            code: 'Stock on hand',
            value: sb.stockOnHand
          };
        case 'reserved':
          return {
            code: 'Reserved',
            value: sb.reserved
          };
        case 'canSell':
          return {
            code: 'Can sell',
            value: boolFormatter(sb.canSell)
          };
        case 'transferPrice':
          return {
            code: 'Transfer price',
            value: poundFormatter(sb.transferPrice)
          };
        case 'forwardOrder':
          return {
            code: 'Forward order',
            value: boolFormatter(sb.forwardOrder)
          };
        case 'backOrder':
          return {
            code: 'Back order',
            value: boolFormatter(sb.backOrder)
          };
        case 'releaseDate':
          return {
            code: 'Release date',
            value: dateHourFormatter(sb.releaseDate)
          };
        default:
          return { code: key, value: sb[key] };
      }
    });
  };

  useEffect(() => {
    switch (audit.operation) {
      case 'insert':
        return setColor(cavendishTheme.colors.green);
      case 'delete':
        return setColor(cavendishTheme.colors.vodafoneRed);
      default:
        return setColor(cavendishTheme.colors.darkGrey);
    }
  }, [audit.operation]);

  return (
    <StyledAudit color={color} height={height}>
      <div className='trail-column'>
        <p className='trail-column__timestamp trail-column__timestamp--small'>
          {hourFormatter(audit.timestamp)}
        </p>
      </div>

      <div className='trail-row'>
        <div className='trail-column'>
          <div className='circle'>{audit.operation}</div>
        </div>
        <InnerAccordion
          title={audit.system}
          description={
            audit.filename
              ? { code: 'File', value: audit.filename }
              : { code: 'User', value: audit.user }
          }
          setHeight={setHeight}
        >
          {audit.channels.map((ch: any) => (
            <div key={ch.channelId} className='trail-content'>
              <h5 className='inner-accordion__details__subtitle'>
                {ch.channelId} - {ch.channelName}
              </h5>
              {ch.subinventories.map((sb: any) => (
                <ExpandRight
                  key={sb.subinventory}
                  title={sb.subinventory}
                  subinventoryEntries={getEntries(sb)}
                />
              ))}
            </div>
          ))}
        </InnerAccordion>
      </div>
      <div className='trail-column'>
        <div className='bar bar--dynamic' />
      </div>
    </StyledAudit>
  );
};

export default AuditStep;
