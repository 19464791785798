import styled from 'styled-components';

export default styled.div`
  border: 1px solid ${(props) => props.theme.colors.grey};
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: ${(props: { expanded: boolean }) =>
    props.expanded
      ? '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
      : 'none'};

  .parent-order__title {
    h2 {
      font-size: 42px;
      margin-top: 10px;
      margin-left: 10px;
      margin-bottom: 0;
    }

    p {
      font-size: 22px;
      margin: 10px 10px;
    }
  }

  .parent-order__button {
    text-transform: none;
    font-family: Vodafone Rg;
    color: ${(props) => props.theme.colors.black};
    font-size: 22px;
    width: 100%;

    .MuiButton-label {
      display: flex;
      justify-content: center;
      padding: 10px;

      img {
        margin-left: 20px;
        -webkit-transition: -webkit-transform 0.2s ease-in-out;
        -ms-transition: -ms-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transform: rotate(
          ${(props: { expanded: boolean }) => (props.expanded ? 180 : 0)}deg
        );
      }
    }
  }

  .parent-order__content {
    background-color: ${(props) => props.theme.colors.lightGrey};
    padding: 10px;
  }
`;
