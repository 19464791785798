import React, { useEffect } from 'react';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';
import { withResizeDetector } from 'react-resize-detector';

import StyledAccordion from './styles';
import GenericEntry from '../GenericSection/components/GenericEntry';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import InnerExpandIcon from '../../assets/icons/inner-expand.svg';

interface IInnerAccordionProps {
  width: number;
  height: number;
  title: string;
  description: IGenericEntry;
  setHeight?: CallableFunction;
}

const InnerAccordion: React.SFC<IInnerAccordionProps> = ({
  width,
  height,
  title,
  description,
  setHeight,
  children
}) => {
  useEffect(() => {
    if (setHeight) {
      setHeight(height);
    }
  }, [height, setHeight]);

  return (
    <StyledAccordion className='accordion--angular'>
      <AccordionSummary
        expandIcon={<img src={InnerExpandIcon} alt='Expand' />}
        className='inner-accordion__summary accordion__summary--outlined'
      >
        <h4>{title}</h4>
        <GenericEntry code={description.code} value={description.value} />
      </AccordionSummary>
      <AccordionDetails className='inner-accordion__details accordion__details--display-column'>
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default withResizeDetector(InnerAccordion);
