import styled from 'styled-components';
import { Dialog } from '@material-ui/core';

export default styled(Dialog)`
  .close-btn {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }

  #dialog-title h2 {
    font-size: 56px !important;
    line-height: 62px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  h3 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 35px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  .dialog-subtitle {
    background-color: ${(props) => props.theme.colors.darkGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.shadowGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 47px;

    p {
      color: white;
      font-family: Vodafone Rg;
      font-size: 20px;
      line-height: 22px;
      margin: 0;
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialog-content__form {
      display: flex;
      flex-direction: column;
      width: 20%;

      & > * {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;
