import React from 'react';

import { dateFormatter, hourFormatter } from '../../../../utils';
import { IDeliveryStatus } from '../../../../interfaces/IOrder';
import StyledDeliveryPipeline from './styles';

const DeliveryPipeline: React.FC<{ history: IDeliveryStatus[] }> = ({
  history
}) => (
  <StyledDeliveryPipeline>
    <h5 className='inner-accordion__details__subtitle'>Delivery pipeline</h5>
    {history.map((delivery: IDeliveryStatus) => (
      <div key={delivery.date} className='delivery-pipeline'>
        <div className='delivery-pipeline__status'>
          <div className='delivery-pipeline__status__circle'>
            {delivery.description}
          </div>
          <div className='delivery-pipeline__status__circle--sm' />
          <hr />
          <div className='delivery-pipeline__status__circle--md' />
          <div className='delivery-pipeline__status__date'>
            <p>{dateFormatter(delivery.date)}</p>
            <p>{hourFormatter(delivery.date)}</p>
          </div>
        </div>
        <div className='delivery-pipeline__vl' />
      </div>
    ))}
  </StyledDeliveryPipeline>
);

export default DeliveryPipeline;
